var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.closeSuccessModal },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            attrs: {
              items: _vm.data,
              search: _vm.search,
              headers: _vm.dataHeader,
            },
            scopedSlots: _vm._u([
              {
                key: "item.index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "text-h4 pt-4",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "mt-3 mx-3" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass:
                                          "font-weight-bold text-h4 my-3",
                                      },
                                      [
                                        _vm._v(
                                          " This is the title for the table "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("AFilter", {
                                  attrs: { filterHeaders: _vm.dataHeader },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }